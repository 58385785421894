/**
 * frontend.js
 *
 * 1.0 CSS Transitions aktivieren
 * 2.0 Sticky Header Resizing
 * 3.0 Suche
 * 4.0 Menu
 * 5.0 GDPR Consent regelmäßig syncen
 *     5.1 Consent beim Seitenaufruf melden
 *     5.2 Consent regelmäßig überprüfen und bei Änderungen reagieren
 * 6.0 Status Abgleich mit Website
 *     6.1 Basket Status on Page Ready melden
 *     6.2 Auf EVO pushToBasket Event reagieren
 *     6.3 Wishlist Status (Nur sichtbar, wenn eingeloggt)
 * 7.0 Produktliste: Höhenangleich der Produktbox pro Zeile
 * 8.0 Workaround Vigneta Font Abstand zwischen "Motiv" und "Zuckerl"
 * 
 * @author jjarolim
 */

// import { debounce } from './frontend/utils';
// import { sendBasketStatus, sendConsentInformation, sendWishlistStatus } from './cross-domain-connector';
// import { getBasketStatus, getWishlistCount } from './jtl.io';

import { init as initNewsletterPopup } from './frontend/newsletter-popup';
import { init as initEnableCssTransitions } from './frontend/enable-css-transitions';
import { init as initMainMenu } from './frontend/main-menu';
import { init as initResizeStickyHeader } from './frontend/resize-sticky-header';
import { init as initGdprConsentManager } from './frontend/gdpr-consent-manager';
import { init as initSyncBasketAndWishlist } from './frontend/sync-basket-and-wishlist';
import { init as initLayoutFixes } from './frontend/layout-fixes';

initNewsletterPopup();
initEnableCssTransitions();
initResizeStickyHeader();
initMainMenu();
initGdprConsentManager();
initSyncBasketAndWishlist();
initLayoutFixes();
