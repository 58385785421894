/**
 * layout-fixes.js
 * 
 * Manche Layoutanpassungen lassen sich ohne große Markup-Änderungen nicht lösen:
 * Wir helfen hier mit JavaScript nach
 * 
 * @autor jjarolim, adwerba
 */

import $ from 'jquery';
import { debounce } from './utils';

export const init = () => {

	// 7.0 Produktliste: Höhenangleich der Produktbox pro Zeile
	//     Die einzelnen Blöcke sollen gleich hoch sein, damit alle
	//     "In den Warenkorb" Buttons auf gleicher Höhe dargestellt werden

	const updateColHeights = () => {

		const selector = '.product-list.layout-gallery .product-wrapper .productbox-inner .col:last-of-type';

		// Cols in Zeilen-Gruppen einteilen und
		// maximale Höhe feststellen

		let colMaxHeights = {};

		$( selector ).each( function() {

			const $this = $( this );
			const offsetTop = Math.floor( $this.offset().top );
			
			$this.attr( 'data-colgroup', offsetTop );

			let colMaxHeight = colMaxHeights[ offsetTop ]
				? colMaxHeights[ offsetTop ]
				: 0;

			if ( colMaxHeight < $this.innerHeight() ) {
				colMaxHeights[ offsetTop ] = $this.innerHeight();
			}

		} );


		// Höhe setzen

		$( selector ).each( function() {
			
			const $this = $( this );
			const colGroup = $this.attr( 'data-colgroup' );
			const targetHeight = colMaxHeights[ colGroup ];

			$this.css( {
				height: targetHeight + 'px'
			} );

			$( '.form-group', this ).css( {
				position: 'absolute',
				left: '15px',
				right: '15px',
				bottom: 0
			} );

		} );

	}

	$( function() { updateColHeights() } );
	$( window ).on( 'resize', debounce( updateColHeights, 250 ) );

	
	//
	// 8.0 Workaround Vigneta Font Abstand zwischen "Motiv" und "Zuckerl": 
    //     Der Font gibt das nicht her, dass das sauber dargestellt wird - 
    //     Wir fügen selbst 3 non breaking spaces ein
	//

	$( function() {
		
		const $headline = $( '#content .title h1.h2' );
		
		if ( $headline.html() === 'Motiv Zuckerl' ) {
			$headline
				.html( 'Motiv&nbsp;&nbsp;&nbsp;Zuckerl' )
				.prepend( '<!-- Corrected by frontend.js -->' )
				.append( '<!-- /Corrected by frontend.js -->' );
		}

	} );

}