/**
 * gdpr-consent-manager.js
 * 
 * @author jjarolim
 */

import $ from 'jquery';
import { sendBasketStatus, sendConsentInformation, sendWishlistStatus } from '../cross-domain-connector';

export const init = () => {

    $( function() {

        // 5.1 Consent beim Seitenaufruf an Website melden

		sendConsentInformation( 
			JSON.parse( 
				localStorage.getItem( 'consent' ) 
			)
		);

        // 5.2 Consent regelmäßig überprüfen und bei Änderungen reagieren

        let	previousConsent = localStorage.getItem( 'consent' ); // Initial state

        window.setInterval( function() {

            const consent = localStorage.getItem( 'consent' );

            // Hat sich am Consent etwas geändert?
        
            if ( consent === previousConsent ) {
                return;
            }

            // Status updaten
        
            previousConsent = consent;

            // Parsen und weitermelden
        
            const parsedConsent = JSON.parse( localStorage.getItem( 'consent' ) );
            sendConsentInformation( parsedConsent );
        
        }, 1000 );

    } );

}