/**
 * main-menu.js
 * 
 * @author jjarolim
 */

import $ from 'jquery';
import { debounce } from './utils';

export const init = () => {

	/**
	 * 4.0 Menu
	 */

	// Mobile Menu: Schließen beim Wechsel von Mobile zu Desktop Breakpoint

	const openMenu = () => {
		$( '.header--mainmenu .menu-wrap' ).addClass( 'menu-wrap--open');
	};

	const closeMenu = () => {
		$( '.header--mainmenu .menu-wrap' ).removeClass( 'menu-wrap--open' );
	}

	const isMenuOpen = () => {
		return $( '.header--mainmenu .menu-wrap' ).hasClass( 'menu-wrap--open' );
	}

	const updateMenu = () => {

		const width =  $( window ).outerWidth();

		if ( width > 991 ) { // Wechsel von Mobile auf Desktop
			
			if ( isMenuOpen() ) {
				closeMenu(0);
				$( '.header--mainmenu .menu' ).removeClass( '.menu--visible' );
			}

		}

	};

	$( function() { updateMenu(); } );
	$( window ).on( 'resize', debounce( updateMenu, 25 ) );

	// Mobile Menu: Öffnen / Schließen

	$( '.cta--mainmenu-open' ).on( 'click', openMenu );
	$( '.cta--mainmenu-close' ).on( 'click', closeMenu );

	// Desktop Menu: SVG Pfeile ins DOM einfügen, damit sie via CSS formatierbar sind

	$( '.menu-item-has-children > a' ).after( '<svg xmlns="http://www.w3.org/2000/svg" width="11.812" height="7" viewBox="0 0 11.812 7"><path d="M617.5,295l5.913-5.889L622.3,288l-4.8,4.769L612.71,288l-1.123,1.112Z" transform="translate(-611.594 -288)"/></svg>' );

	// Desktop Menu: Beim Öffnen eines Submenus eine Klasse zum Body hinzufügen
	// Damit kann man die Transparenz des Inhalts steuern

	$( '.menu-item-has-children' ).hover(
		() => { $( 'body' ).addClass( 'menu-item-has-children-hover' ); },
		() => { $( 'body' ).removeClass( 'menu-item-has-children-hover' ); }
	);

	// Submenu Öffnen / Schließen
	$( '.menu-item-has-children > a' ).on( 'click', function(event) {
		event.preventDefault();
		$(this).nextAll('.sub-menu').toggleClass('sub-menu-open');
	} );

}