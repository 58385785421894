/**
 * resize-sticky-header.js
 * 
 * Wir möchten, dass der große Headerbereich beim Scrollen nach unten kleiner wird
 * 
 * @author jjarolim, adwerba
 */

import $ from 'jquery';
import { debounce } from './utils';

export const init = () => {

	/**
	 * 2.0 Sticky Header verkleinern nach Scrolling
	 * 
	 * Es wird die Klasse header--small hinzugefügt - 
	 * Der Rest passiert via CSS
	 */ 

	const updateHeader = () => {

		const scrollTop = $( window ).scrollTop();
		const scrollMin = $( 'header' ).hasClass( 'header--small' ) ? 0 : 100; // Wie weit muss man gescrollt haben, bevor der Header kleiner wird?

		if ( scrollTop > scrollMin ) {
			$( 'header' ).addClass( 'header--small' );
		} else {
			$( 'header' ).removeClass( 'header--small' );
		}

	};

	$( window ).on( 'scroll', debounce( updateHeader, 50 ) );
	$( function() { updateHeader() } );

}