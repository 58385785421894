/**
 * gdpr-consent-manager-connector.js
 * 
 * 1.0 GDPR Consent
 * 2.0 Warenkob Informationen
 * 3.0 Status Wishlist
 * 
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 * @see https://github.com/erikras/postmessage-client-server
 * @author jjarolim, adwerba
 */

import { client } from 'postmessage-client-server';

const debug = false;
/**
 * 1.0 GDPR Consent
 */



function sendConsentInformation( consent ) {

	debug && console.log( 'sendConsentInformation', consent );

	if ( consent === null ) {
		return
	}

	console.log( 'consent', consent );

	// Übersetzung consentsGiven 
	// @see config.php

	for ( const key in cross_domain_connector_configuration.consent_translations ) {
		const website_key = cross_domain_connector_configuration.consent_translations[ key ];
		if ( consent.settings.hasOwnProperty( key ) ) {
			consent.settings[ website_key ] = true;
		}
	}

	debug && console.log( 'sendConsentInformation (translated)', consent );


	// Consent an Website melden

	client( cross_domain_connector_configuration.client_url )
	.then( send => {
		send( 'consentsChanged', consent.settings )
		.then( response => {
			debug && console.log( 'sendConsentInformation', 'Received response "' + response + '"' );
		} );
	} );

}



/**
 * 2.0 Warenkorb Informationen 
 */

function sendBasketStatus( data ) {

	debug && console.log( 'sendBasketInformation', data );

	// Warenkorb Infos an Website melden

	client( cross_domain_connector_configuration.client_url )
	.then( send => {
		send( 'basketChanged', data )
		.then( response => {
			debug && console.log( 'sendBasketInformation', 'Received response "' + response + '"' );
		} );
	} );

}

/**
 * 3.0 Status Wishlist
 */

function sendWishlistStatus( data ) {

	debug && console.log( 'sendWishlistStatus', data );

	// LoginStatus an Website melden

	client( cross_domain_connector_configuration.client_url )
	.then( send => {
		send( 'wishlistStatusChanged', data )
		.then( response => {
			debug && console.log( 'sendWishlistStatus', 'Received response "' + response + '"' );
		} );
	} );

}


function sendNewsletterPopupStatus( data ) {

	debug && console.log( 'SHOP', 'sendNewsletterPopupStatus', data );

	// LoginStatus an Website melden

	client( cross_domain_connector_configuration.client_url )
	.then( send => {
		send( 'newsletterPopupClosed', data )
		.then( response => {
			debug && console.log( 'SHOP','sendNewsletterPopupStatus', 'Received response "' + response + '"' );
		} );
	} );

}


export { 
	sendConsentInformation, 
	sendBasketStatus, 
	sendWishlistStatus,
	sendNewsletterPopupStatus
}