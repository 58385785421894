/**
 * sync-basket-and-wishlist.js
 * 
 * Der Status von Basket und Wishlist sollen auch auf der Website angezeigt werden.
 * Dazu sendet dieses Script die entsprechenden Informationen an die Website.
 * 
 * @author jjarolim, adwerba
 */

import $ from 'jquery';
import { getBasketStatus, getWishlistCount } from './jtl.io';
import { sendBasketStatus, sendWishlistStatus } from '../cross-domain-connector';

const debug = false

export const init = () => {

    /**
     * 6.0 Status Abgleich mit Website
     */

    // 6.1 Basket Status on Dcoument Ready melden

    $( function() {
        getBasketStatus( function( basketStatus ) {
            sendBasketStatus( basketStatus );
        } );
    } );


    // 6.2 Auf EVO pushToBasket Event reagieren
    // @see templates/Evo/js/jtl.io.js

    $( document ).on( 'evo:load.io.request', function( event, req ) {
        debug && console.log( 'frontend.js', 'evo:load.io.request', req, { 'io': JSON.stringify( req ) } );
    } );

    $( document ).on( 'evo:loaded.io.request', function( event, data ) {

        // pushToBasket event?

        if ( ! data.req || ! data.req.name || data.req.name !== 'pushToBasket' ) {
            return;
        }

        // success?

        if ( ! data.status || data.status !== 'success' ) {
            return;
        }

        getBasketStatus( function( basketData ) {

            // Haben wir Items im Basket?

            if ( basketData.itemCount < 1 ) {

                // Nope: Wir aktualisieren den Header entsprechend

                $( 'nav.header--shop .icon--shopping-cart .count' ).remove();
                $( 'nav.header--shop .sum--shopping-cart' ).html( '0,00 &euro;' );

                // Meldung an die Website

                sendBasketStatus( basketData );

                return;

            }

            // Ja, wir haben Items

            if ( $( 'nav.header--shop .icon--shopping-cart .count' ).length < 1 ) {
                $( 'nav.header--shop .icon--shopping-cart' ).append( '<span class="count count--wishlist"></span>' ); // Count indicator hinzufügen, falls noch nicht vorhanden
            }

            $( 'nav.header--shop .icon--shopping-cart .count' ).html( basketData.itemCount );
            $( 'nav.header--shop .sum--shopping-cart' ).html( basketData.itemAmmountLocalized );

            sendBasketStatus( basketData );

        } );

    } );


    // 6.3 Wishlist Status (Nur sichtbar, wenn eingeloggt)
    //     Das Update wird nur on document ready ausgeführt, da 
    //     das Hinzufügen eines neuen Artikels immer einen 
    //     Page Reload auslöst

    $( function() {
        getWishlistCount( function( data ) {
            sendWishlistStatus( data.currentPosCount );
        } );
    } );

}