/**
 * enable-css-transitions.js
 * 
 * Wir fügen beim rendern zum body die Klasse disable-css-transitions hinzu.
 * Das verhindert im CSS, dass CSS-Transitions beim Page Load ausgeführt werden.
 * 
 * Sobald die Seite geladen ist, entfernt dieses Script die Klasse und CSS Transitions sind wieder aktiv.
 * 
 * @author jjarolim, adwerba
 */

import $ from 'jquery';

export const init = () => {
    $( window ).on( 'load', () => {
        $( 'body' ).removeClass( 'disable-css-transitions' );
    } );
}

