/**
 * newsletter-popup.js
 * 
 * @author jjarolim, adwerba
 */

import $ from 'jquery';
import Cookies from 'js-cookie';
import { sendNewsletterPopupStatus } from '../cross-domain-connector';

const NEWSLETTER_POPUP_COOKIE_NAME = 'newsletter-promo-seen'
const NEWSLETTER_POPUP_TIMEOUT = 5000

const debug = false;


/**
 * Die Funktion speichert den Status des Newsletter-Popups
 */
export const storePopupClosedState = () => {

    // Wir setzen ein 14 Tage gültiges Cookie

    Cookies.set( NEWSLETTER_POPUP_COOKIE_NAME, JSON.stringify( new Date() ), { expires: 14 } );

    // Wir übermitteln den Status an die Website

    sendNewsletterPopupStatus( new Date() );

}

export const isPopupClosed = () => {
    return Cookies.get( NEWSLETTER_POPUP_COOKIE_NAME ) !== undefined
}

export const isCookieConsentGiven = () => {
    return localStorage.getItem( 'consent' ) !== null
}

const showNewsletterPopup = () => {
    // Popup initialisieren

    $( function() {

        $( 'body' ).addClass( 'is-newsletter-popup-opened' );

        $( '.zuckerlwerkstatt-newsletter-popup' ).css( 'display', 'flex' ).hide().fadeIn( 1000 ); // Einfaden

        $( '.zuckerlwerkstatt-newsletter-popup .cta-close' ).on( 'click', function(e) {

            e.preventDefault();
            e.stopPropagation();

            storePopupClosedState();

            // Wir triggern ein Event: Darauf hört der Code, der den Status an den Shop weitergibt
            // @see resources/js/cross-domain-connector

            dispatchEvent( new CustomEvent( 'newsletter-popup-closed', { detail: new Date() } ) )

            // Wir blenden das Popup aus
            $( 'body' ).removeClass( 'is-newsletter-popup-opened' );

        } );

        $( '.zuckerlwerkstatt-newsletter-popup .btn' ).on( 'click', function(e) {

            e.preventDefault();
            e.stopPropagation();

            // Merken, dass das Popup geschlossen wurde

            storePopupClosedState();

            // Auf Ziel-URL weiterleiten

            const href = $( this ).closest( 'a' ).attr( 'href' );
            window.location.href = href;

        } );

    } );
}

export const init = () => {

    debug && console.log( 'newsletter-popup.js init' )

    // Wir wollen das Newsletter Popup nicht anzeigen, falls
    // - es in den letzten 2 Wochen schon geöffnet wurde (Cookie mit 2 Wochen Lebensdauer),
    // - wenn noch kein consent gegeben wurde (Der JTL Consent Manager speichert den Consent in localStorage)
    //   In diesem Fall möchten wir das Popup eine gewisse Zeit nach Schließen des JTL Consent Managers anzeigen
    
    // Wenn es das Newsletter Cookie gibt, öffnen wir das Popup nicht

    if ( isPopupClosed() ) {
        debug && console.log( 'popup already closed' );
        return
    } else {
        debug && console.log( 'popup not yet opened' );
    }

    // Wenn wir schon einen Consent haben, dann wird das Newsletter-Popup direkt angezeigt...

    if ( isCookieConsentGiven() ) {
        debug && console.log( 'Cookie consent already given (1)' );
        showNewsletterPopup()
    } else {
        debug && console.log( 'Cookie consent not yet given (1)' );
    }

    // Wir haben noch keinen Consent: Wir hooken uns in das Event consent.update ein
    // @see templates/NOVA/js/consent.js

    document.addEventListener(
        'consent.updated',
        function( event ) {

            debug && console.log( 'consent.updated' );

            // ... leider wird 'consent.updated' auch schon getriggert, wenn der Consent-Manager geladen wird.
            // Das Popup soll aber erst angezeigt werden, wenn tatsächlich auch ein Consent gespeichert wurde...

            if ( ! isCookieConsentGiven() ) {
                debug && console.log( 'Cookie consent not yet given (2)' );
                return;
            } else {
                debug && console.log( 'Cookie consent already given (2)' );
            }

            // ... und dann auch erst nach einer gewissen Zeit

            setTimeout( showNewsletterPopup, NEWSLETTER_POPUP_TIMEOUT );

        }
    )

}
