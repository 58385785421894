/**
 * jtl-io.js
 * 
 * Verbindung zur JTL io.php
 *
 * @author jjarolim, adwerba
 */

const endpointUri = '/io.php';
const debug = false;

export function getBasketStatus( callback ) {

	debug && console.log( 'getBasketStatus' );

	call( 'getBasketItems', [1], function( data ) {

		if ( ! data.cItems ) {
			callback( { itemCount: 0 } );
		}

		const itemCount = data.cItems.length;

		// Haben wir Items im Basket?

		if ( itemCount < 1 ) {
			callback( { 'itemCount': 0 } );
		}

		// Ja, wir haben Items

		let itemAmmount = 0.0;

		// Wir zählen die Einzelbeträge zusammen, damit wir den Header aktualisieren können.
		// Leider bekommen wir von der JTL-Schnittstelle nur lokalisierte Strings, die 
		// wir zuerst zu Floats parsen müssen

		for ( const index in data.cItems ) {

			const cItem = data.cItems[ index ];

			let gesamtPreisParsed = parseFloat(
				cItem.cGesamtpreisLocalized[0].EUR.replace( ',', '.' ).replace( ' &euro;', '' )
			);
			
			itemAmmount += gesamtPreisParsed;

		}

		const itemAmmountLocalized = itemAmmount.toLocaleString( 'de-DE', { style: 'currency', currency: 'EUR' } );

		callback( {
			'itemCount': itemCount,
			'itemAmmount': itemAmmount,
			'itemAmmountLocalized': itemAmmountLocalized
		} );

	} );

}

export function getWishlistCount( callback ) {

	debug && console.log( 'getWichlistCount' );

	call( 'getWishlistCount', [], callback );

}




/**
 * @param string name 
 * @param array params 
 * @param function callback 
 */
function call( name, params, callback ) {

	debug && console.log( 'call', name, params );

	$.ajax( {
		type: 'POST',
		dataType: 'JSON',
		url: endpointUri,
		data: { 
			io: JSON.stringify( { 
				name: name,
				params: params
			} )
		},
		success: function( data, textStatus, errorThrown ) {

			debug && console.log( 'call', 'success', data, textStatus );

			if ( ! data.varAssigns ) {
				callback( null );
			}

			let response = {};

			for ( const index in data.varAssigns ) {

				if ( ! data.varAssigns[ index ].name ) {
					continue;
				}

				const name = data.varAssigns[ index ].name;

				if ( name !== 'response' ) {
					continue;
				}

				if ( ! data.varAssigns[ index ].value ) {
					continue;
				}

				response = data.varAssigns[ index ].value;

				debug && console.log( 'call', 'response', response );

			}

			callback( response );

		},
		error: function( jqXHR, textStatus, errorThrown ) {
			debug && log.warn( 'jtl.io', 'call', textStatus );
		}
	} );	

}
